import React from "react";
import "../Css/Career.css";
import careerhero from "../almabetter resoures/career-hero.png";
import carreroffer from "../almabetter resoures/carrer-offer.png";

const data = [
 
  {
    name: "MERN Stack Developer",
    age: "IT Software",
    gender: "Pune",
    Roll_Number: "Minimum 0-1 Year",
    link: "Apply Now",
  },

  {
    name: "Python Developer",
    age: "IT Software",
    gender: "Pune",
    Roll_Number: "Minimum 0-1 Year",
    link: "Apply Now",
  },

  
  {
    name: "Data Scientist",
    age: "IT Software",
    gender: "Pune",
    Roll_Number: "Minimum 0-1 Year",
    link: "Apply Now",
  },
 
 
];

const Career = () => {
  return (
    <>
      {/* First Section Start-- Career Hero */}
      <div className="career-container">
        <div className="career-content">
          <div className="career-text">
            <h1>
              Think You Could Fit
              <br /> In Discover Your Next Career Move with
              <span className="highlight" style={{background:'none'}}> Speedupinfotech</span>
            </h1>
          </div>
          <div className="career-image">
            <img src={careerhero} alt="impact-graph" />
          </div>
        </div>
      </div>

      {/* First Section Start-- Career Hero */}

      {/* Second Section Start -- Apply Jobs */}
      <div>
        <div className="career-page">
          <h1 className="career-title">Current Job Openings</h1>
          <div className="job-cards">
            {data.map((value, key) => (
              <div key={key} className="job-card">
                <div className="job-card-content">
                  <h2 className="job-title">{value.name}</h2>
                  <p className="job-category">{value.age}</p>
                  <p className="job-location">{value.gender}</p>
                  <p className="job-experience">{value.Roll_Number}</p>
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdcw-z9fT6ooipuzg77_UJVLOCDTfNBY8HcB4KmW6DzloitCw/viewform?usp=sharing"
                    target="_blank"
                    rel="noreferrer"
                    className="apply-button"
                  >
                    {value.link}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Second Section End -- Apply Jobs */}

      {/* Third Section Start -- Apply Jobs */}
      <div style={{ marginTop: "110px", marginBottom: "110px" }}>
        <h1 style={{ textAlign: "center" }}>
          <span style={{ color: "#ed2d16" }}>Careers</span> We Offer
        </h1>
        <img
          style={{ width: "100%", height: "auto", objectFit: "contain" }}
          src={carreroffer}
          alt="banner"
        />
      </div>
      {/* Third Section End -- Apply Jobs */}
    </>
  );
};

export default Career;
