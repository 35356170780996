import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import logo from "../almabetter resoures/logo.png";
import "../Css/Navbar.css";
import EnquiryForm from "./UI/EnquiryForm"; // Import the EnquiryForm
import { FaArrowUp } from "react-icons/fa"; // Import the arrow icon

const Navbar = () => {
  const [sideBar, setSideBar] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isEnquiryFormVisible, setEnquiryFormVisible] = useState(false); // State to manage EnquiryForm visibility
  const [showScrollTopButton, setShowScrollTopButton] = useState(false); // State for scroll-to-top button

  // Toggle sidebar for mobile view
  const toggleSidebar = () => {
    setSideBar(!sideBar);
  };

  // Toggle dropdowns for nested menu items
  const toggleDropdown = (menu) => {
    if (activeDropdown === menu) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(menu);
    }
  };

  // Close sidebar when a link is clicked
  const closeSidebar = () => {
    if (window.innerWidth < 1120) {
      setSideBar(false);
    }
  };

  // Show the Enquiry Form after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setEnquiryFormVisible(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  // Show or hide the scroll-to-top button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollTopButton(true);
      } else {
        setShowScrollTopButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <header className={`sp-navbar ${sideBar ? "active" : ""}`}>
        <div className="sp-navbar-container">
          <div className="sp-navbar-mobile">
            <div className="sp-navbar-toggle" onClick={toggleSidebar}>
              <span className="sp-navbar-toggle-icon"></span>
              <span className="sp-navbar-toggle-icon"></span>
              <span className="sp-navbar-toggle-icon"></span>
            </div>
            <div className="sp-navbar-logo">
              <NavLink to="/" onClick={closeSidebar}>
                <img src={logo} alt="Logo" />
              </NavLink>
            </div>
            <div className="sp-navbar-enquire">
              <button
                className="sp-enquire-button"
                onClick={() => setEnquiryFormVisible(true)} // Show EnquiryForm on click
              >
                Enquire Now
              </button>
            </div>
          </div>
          <div className="sp-main-navbar-logo">
            <NavLink to="/" onClick={closeSidebar}>
              <img
                src={logo}
                alt="Logo"
              />
            </NavLink>
          </div>
          <nav className={`sp-navbar-menu ${sideBar ? "active" : ""}`}>
            <NavLink
              to="/aboutus"
              className="sp-navbar-link"
              onClick={closeSidebar}
            >
              About Us
            </NavLink>
        
            <div className="sp-navbar-dropdown">
              <button
                className="sp-navbar-link"
                onClick={() => toggleDropdown("services")}
                style={{ fontSize: '17px' }}
              >
                Courses
              </button>
              {activeDropdown === "services" && (
                <div className="sp-dropdown-menu">
                  <NavLink
                    to="/mernstack"
                    className="sp-dropdown-item"
                    onClick={closeSidebar}
                  >
                   MERN Stack 
                  </NavLink>
                  <NavLink
                    to="/pythonfullstack"
                    className="sp-dropdown-item"
                    onClick={closeSidebar}
                  >
                   Python Full Stack
                  </NavLink>
                  <NavLink
                    to="/datascience"
                    className="sp-dropdown-item"
                    onClick={closeSidebar}
                  >
                    Data Science
                  </NavLink>
                  <NavLink
                    to="/dataanalyst"
                    className="sp-dropdown-item"
                    onClick={closeSidebar}
                  >
                    Data Analyst
                  </NavLink>
                </div>
              )}
            </div>
            <div className="sp-navbar-dropdown">
              <button
                className="sp-navbar-link"
                onClick={() => toggleDropdown("services")}
                style={{ fontSize: '17px' }}
              >
                Services
              </button>
              {activeDropdown === "services" && (
                <div className="sp-dropdown-menu">
                  <NavLink
                    to="/logodesign"
                    className="sp-dropdown-item"
                    onClick={closeSidebar}
                  >
                    Logo Design
                  </NavLink>
                  <NavLink
                    to="/websitedev"
                    className="sp-dropdown-item"
                    onClick={closeSidebar}
                  >
                    Website Dev
                  </NavLink>
                  <NavLink
                    to="/androidios"
                    className="sp-dropdown-item"
                    onClick={closeSidebar}
                  >
                    Android IOS
                  </NavLink>
                  <NavLink
                    to="/itstaffing"
                    className="sp-dropdown-item"
                    onClick={closeSidebar}
                  >
                    IT Staffing
                  </NavLink>
                </div>
              )}
            </div>
            <div className="sp-navbar-dropdown">
              <button
                className="sp-navbar-link"
                onClick={() => toggleDropdown("placements")}
                style={{ fontSize: '17px' }}
              >
                Placements
              </button>
              {activeDropdown === "placements" && (
                <div className="sp-dropdown-menu">
                  <NavLink
                    to="/hirefromus"
                    className="sp-dropdown-item"
                    onClick={closeSidebar}
                  >
                    Hire From Us
                  </NavLink>
                  <NavLink
                    to="/placementsstatistics"
                    className="sp-dropdown-item"
                    onClick={closeSidebar}
                  >
                    Placement Statistics
                  </NavLink>
                </div>
              )}
            </div>
            <NavLink
              to="/career"
              className="sp-navbar-link"
              onClick={closeSidebar}
            >
              Career
            </NavLink>
            <NavLink
              to="/blog"
              className="sp-navbar-link"
              onClick={closeSidebar}
            >
              Blog
            </NavLink>
            <NavLink
              to="/contactus"
              className="sp-navbar-link"
              onClick={closeSidebar}
            >
              Contact Us
            </NavLink>
          </nav>
          <div className="sp-navbar-desktop-enquire">
            <button
              className="sp-enquire-button"
              onClick={() => setEnquiryFormVisible(true)} // Show EnquiryForm on click
            >
              Enquire Now
            </button>
          </div>
        </div>
      </header>

      {/* Enquiry Form Modal */}
      {isEnquiryFormVisible && (
        <EnquiryForm
          isVisible={isEnquiryFormVisible}
          onClose={() => setEnquiryFormVisible(false)} // Hide the form on close
        />
      )}

      {/* Call and WhatsApp Buttons */}
      <div className="fixed-buttons">
        <a className="call-button" href="tel:+91 7219777599">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            fill="currentColor"
            className="bi bi-telephone-fill"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
            />
          </svg>
        </a>
        <a
          className="whatsapp-button"
          href="https://wa.link/tru4sa"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            fill="currentColor"
            className="bi bi-whatsapp"
            viewBox="0 0 16 16"
          >
            <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.098-1.174-.581-1.356-.647-.182-.065-.314-.098-.446.099-.13.196-.512.646-.627.779-.114.13-.23.147-.427.049-.197-.098-.832-.306-1.586-.98-.586-.521-.984-1.165-1.101-1.362-.115-.197-.012-.303.086-.401.088-.088.196-.23.294-.345.099-.114.13-.196.197-.327.065-.131.033-.246-.016-.344-.049-.098-.446-1.078-.611-1.481-.161-.394-.326-.34-.446-.346-.114-.007-.246-.009-.378-.009a.73.73 0 0 0-.53.246 2.229 2.229 0 0 0-.713 1.675c0 .984.72 1.937.82 2.07.098.13 1.417 2.169 3.44 3.04.481.207.856.33 1.148.423.482.153.92.132 1.268.08.387-.058 1.174-.48 1.34-.943.164-.462.164-.858.115-.943-.05-.083-.182-.131-.379-.23z" />
          </svg>
        </a>
      </div>

      {/* Back to Top Button */}
      {showScrollTopButton && (
        <button className="back-to-top-button" onClick={scrollToTop}>
          <FaArrowUp />
        </button>
      )}
    </>
  );
};

export default Navbar;
